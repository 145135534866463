


























































































import { api, binaryApi } from "@/api/api";
import {
  ApiAttachmentDto,
  ApiGetCourseDto,
  ApiGetCourseParticipantDto,
  ApiGetMessageResultDto,
} from "@/api/generated/Api";
import CourseViewFileModal from "@/components/course/details/files/CourseViewFileModal.vue";
import CourseSendMessageModal from "@/components/course/details/messages/CourseSendMessageModal.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { formatDateTime } from "@/shared/helpers/dateHelpers";
import { getInitialFilePreview } from "@/shared/helpers/fileViewHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { mapMessageResponse } from "@/shared/helpers/messageHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import "@/shared/styles/toastui-editor.scss";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/vue-editor";
import { computed, defineComponent, onMounted, Ref, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseMessagePage",
  components: { BaseModal, CourseSendMessageModal, BaseLayout, Viewer, CourseViewFileModal },
  setup() {
    const route = useRoute();
    const store = useStore<StoreState>();
    const courseId = +route.params.id;
    const course = ref<ApiGetCourseDto | null>(null);
    const messageId = +route.params.messageId;

    const showReplyModal = ref(false);
    const message = ref<ApiGetMessageResultDto | null>(null);
    const participants = ref<ApiGetCourseParticipantDto[]>([]);
    const showFilePreview = ref(false);
    const filePreview = ref(getInitialFilePreview());

    const markUnread = async () => {
      await setMessageReadStatus(messageId, false);
      useNavigateBack(SingleCourseRouteNames.CourseMessages);
    };

    const markRead = async () => {
      await setMessageReadStatus(messageId, true);
      useNavigateBack(SingleCourseRouteNames.CourseMessages);
    };

    const answerEmail = () => {
      showReplyModal.value = true;
    };

    const viewFile = async (file: ApiAttachmentDto) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!file.fileName) {
          return;
        }
        try {
          const attatchement = await binaryApi.file.downloadFileAsync(file.fileId, { format: "blob" });
          const blob = new Blob([attatchement.data], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          filePreview.value = {
            name: file.fileName,
            mimeType: "application/pdf",
            source: url,
          };
          showFilePreview.value = true;
        } catch {
          openNotification(store, NotificationItemType.Error, `En feil oppsto ved visning av ${file.fileName}.`);
        }
      });
    };

    const closeViewFileModal = () => {
      showFilePreview.value = false;
      filePreview.value = getInitialFilePreview();
    };

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const participantsResponse = await api.course.getCourseParticipantsByCourseIdAsync(courseId, {
          IsActive: true,
        });
        participants.value = participantsResponse.data;
        await Promise.allSettled([loadMessage(messageId, message), loadCourse(course, courseId)]);
      });
    });

    return {
      markRead,
      markUnread,
      answerEmail,
      viewFile,
      closeViewFileModal,
      filePreview,
      showFilePreview,
      showReplyModal,
      ModalType,
      messageId,
      message,
      participants,
      course: computed(() => store.state.courses.course),
      formattedDate: computed(() => (message.value ? formatDateTime(message.value.sent) : null)),
      hasAttatchment: computed(() => message.value?.attachments?.length),
      goBackToInbox: () => useNavigateBack(SingleCourseRouteNames.CourseMessages),
    };
  },
});

const loadCourse = async (course: Ref<ApiGetCourseDto | null>, courseId: number) =>
  api.course.getCourseByIdFilteredByDepartmentAsync(courseId).then((response) => {
    course.value = response.data;
  });

const setMessageReadStatus = async (messageId: number, isRead: boolean) => {
  globalLoadingWrapper({ blocking: true }, async () => {
    await api.messaging.setReadKursadmin(messageId, {
      isRead,
    });
  });
};

const loadMessage = (messageId: number, message: Ref<ApiGetMessageResultDto | null>) =>
  api.messaging.getMessageByIdKursAdmin(messageId).then((response) => {
    message.value = mapMessageResponse(response.data);
  });
