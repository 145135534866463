




































import { ApiGetSmsResultDto } from "@/api/generated/Api";
import { CourseMessageBoxType } from "@/shared/enums/CourseMessageBoxType.enum";
import { formatDateTime } from "@/shared/helpers/dateHelpers";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseSmsList",
  components: {},
  props: {
    smsList: {
      type: Array as PropType<ApiGetSmsResultDto[]>,
      required: true,
    },
  },
  setup(props) {
    const pageSize = 7;
    const page = ref(1);

    return {
      page,
      pageSize,
      CourseMessageBoxType,
      formatDateTime,
      totalMessages: computed(() => props.smsList.length),
      visibleMessages: computed(() => props.smsList.slice((page.value - 1) * pageSize, page.value * pageSize)),
    };
  },
});
