










































































































































import { api } from "@/api/api";
import {
  ApiGetCommunicationTemplateDto,
  ApiGetCourseParticipantDto,
  ApiGetCommunicationTemplateGroupDto,
} from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { ParticipantRoles } from "@/shared/enums/ParticipantRoles.enum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateMaxLength, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, PropType, Ref, ref, computed } from "@vue/composition-api";
import { Editor } from "@toast-ui/vue-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@/shared/styles/toastui-editor.scss";
import { localeSortByProperty } from "@/shared/helpers/arrayHelpers";
import { isVocationalSchool as useIsVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import BaseLayout from "@/components/shared/BaseLayout.vue";

enum SendToChoice {
  Everyone = 0,
  Person = 1,
  Group = 2,
}

enum MessageTabNames {
  Internal = "internal",
  SMS = "sms",
}

export default defineComponent({
  name: "CourseSendMessageModal",
  components: { BaseModalForm, Editor, BaseLayout },
  props: {
    headline: {
      type: String,
      required: true,
    },
    replyMessageId: {
      type: Number,
      required: false,
    },
    replyMessageSubject: {
      type: String,
      required: false,
    },
    replyMessageRecipient: {
      type: String,
      required: false,
    },
    possibleRecipients: {
      type: Array,
      required: true,
    },
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const courseId = +route.params.id;
    const attatchments = ref<File[]>([]);

    const activeTab = ref(MessageTabNames.Internal);

    const sendToChoice = ref(SendToChoice.Everyone);
    const validForm = ref(false);
    const title = ref("");
    const message = ref("");
    const smsContent = ref("");
    const recipientPeople = ref<number[]>([]);
    const recipientGroups = ref<string | undefined>(undefined);
    const possibleRecipients = props.possibleRecipients as ApiGetCourseParticipantDto[];

    const TEMPLATE_USER_GROUP_TYPE = "user";
    const templateGroups = ref<ApiGetCommunicationTemplateGroupDto[]>([]);
    const activeTemplates = ref<ApiGetCommunicationTemplateDto[]>([]);
    const filteredActiveTemplates = ref<ApiGetCommunicationTemplateDto[]>([]);
    const selectedTemplateGroupId = ref<number>();
    const selectedTemplateId = ref<number>();

    const isVocationalSchool = computed(() => useIsVocationalSchool(store.state.plans.studyplan.mainCourseId));
    const isSms = computed(() => activeTab.value === MessageTabNames.SMS);

    const recipientPeopleList = ref(
      possibleRecipients.map((rec: ApiGetCourseParticipantDto) => ({
        label: (rec as any).userFullName,
        value: rec.userId,
      }))
    );

    const recipientGroupList: Ref<
      {
        label: string;
        value: string;
      }[]
    > = ref([]);

    const getEditorRef = () => context.refs.tuiEditor as Editor;

    const onChangeEditor = () => {
      message.value = getEditorRef().invoke("getMarkdown");
    };

    const onChangeSelectedTemplate = () => {
      if (!selectedTemplateId.value) {
        title.value = "";
        message.value = "";
      } else {
        title.value = getCommunicationTemplateById(selectedTemplateId.value)?.subject ?? "";
        message.value = getCommunicationTemplateById(selectedTemplateId.value)?.content ?? "";
      }
      getEditorRef().invoke("setMarkdown", message.value);
    };

    const getCommunicationTemplateById = (id: number) =>
      filteredActiveTemplates.value.find((template) => template.id === id);

    const onChangeSelectedTemplateGroup = () => {
      filteredActiveTemplates.value = getCommunicationTemplatesByGroupId(selectedTemplateGroupId.value);
    };

    const getCommunicationTemplatesByGroupId = (groupId?: number) => {
      if (!groupId) {
        return activeTemplates.value;
      }
      return activeTemplates.value.filter((template) => template.communicationTemplateGroupId === groupId);
    };

    const loadCommunicationTemplateGroups = async () => {
      templateGroups.value = (await api.communicationtemplategroup.getCommunicationTemplateGroupsAsync()).data
        .filter((templateGroup) => templateGroup.type === TEMPLATE_USER_GROUP_TYPE)
        .sort(localeSortByProperty("name"));
    };

    const loadActiveCommunicationTemplates = async () => {
      activeTemplates.value = (await api.communicationtemplate.getCommunicationTemplatesAsync()).data
        .filter((template) => template.isActive)
        .sort(localeSortByProperty("name"));
      filteredActiveTemplates.value = activeTemplates.value;
    };

    const mapParticipantGroupList = (statuses: string[]) =>
      statuses.map((current) => ({
        label: current.charAt(0).toUpperCase() + current.slice(1),
        value: current,
      }));

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const defaultOptions = await api.course.getCourseDefaultOptions();

        if (!defaultOptions.data.participantStatuses || !defaultOptions.data.vocationalSchoolParticipantStatuses) {
          openNotification(store, NotificationItemType.Error, "Fant ikke kursstatuser");
          return;
        }

        if (isVocationalSchool.value) {
          recipientGroupList.value = mapParticipantGroupList(defaultOptions.data.vocationalSchoolParticipantStatuses);
        } else {
          recipientGroupList.value = mapParticipantGroupList(defaultOptions.data.participantStatuses);
        }

        recipientGroups.value = recipientGroupList.value[0].value;

        await loadCommunicationTemplateGroups();
        await loadActiveCommunicationTemplates();
      });
    });

    if (props.replyMessageSubject) {
      title.value = `RE: ${props.replyMessageSubject}`;
    }

    const handleSubmit = async () => {
      if (!(context.refs.connectForm as any).validate() || (!isSms && !message.value.length)) {
        openNotification(store, NotificationItemType.Error, "Mangler tittel eller melding tekst");
        return;
      }

      globalLoadingWrapper({ blocking: true }, async () => {
        if (props.replyMessageId) {
          await api.messaging.replyTo({
            ReplyTo: props.replyMessageId,
            Subject: title.value,
            Text: message.value,
            Attachments: attatchments.value,
          });
        } else {
          const recipientsToSendTo = getRecipentListForSendMessage(
            recipientPeople.value,
            possibleRecipients,
            sendToChoice,
            recipientGroups
          );
          if (isSms.value) {
            await api.messaging.sendSmsMessageFromKursAdminAsync({
              courseId,
              recipientIds: recipientsToSendTo,
              messageText: smsContent.value,
            });
            openNotification(store, NotificationItemType.Success, "SMS sendt");
            context.emit("close");
            return;
          }
          if (recipientsToSendTo.length === 0) {
            openNotification(store, NotificationItemType.Warning, "Ingen mottakere, melding kan ikke sendes");
            return;
          }

          await api.messaging.sendMessageFromKursAdminAsync({
            CourseId: courseId,
            RecipientIds: recipientsToSendTo,
            Subject: title.value,
            Text: message.value,
            Attachments: attatchments.value,
          });
        }
        openNotification(store, NotificationItemType.Success, "Melding sendt");
        context.emit("close");
      });
    };

    return {
      handleSubmit,
      activeTab,
      title,
      validForm,
      attatchments,
      validateNotEmpty,
      recipientPeople,
      recipientPeopleList,
      message,
      SendToChoice,
      smsContent,
      sendToChoice,
      recipientGroupList,
      recipientGroups,
      templateGroups,
      filteredActiveTemplates,
      selectedTemplateId,
      selectedTemplateGroupId,
      editorOptions,
      validateMaxLength,
      onChangeEditor,
      onChangeSelectedTemplate,
      onChangeSelectedTemplateGroup,
      readonly: computed(() => props.modalType === ModalType.Display),
      isSms,
    };
  },
});

const getRecipentListForSendMessage = (
  selectedValue: number[],
  allPossibleRecipients: ApiGetCourseParticipantDto[],
  sendToChoice: Ref<SendToChoice>,
  recipientGroups: Ref<string | undefined>
) => {
  if (sendToChoice.value === SendToChoice.Everyone) {
    return allPossibleRecipients
      .filter((current) => current.roleName == ParticipantRoles.Student)
      .map((current) => current.userId);
  }
  if (sendToChoice.value === SendToChoice.Group && recipientGroups) {
    return allPossibleRecipients
      .filter((current) => current.status === recipientGroups.value && current.roleName == ParticipantRoles.Student)
      .map((current) => current.userId);
  }
  return selectedValue;
};

const editorOptions = {
  minHeight: "200px",
  language: "nb-NO",
  useCommandShortcut: true,
  usageStatistics: true,
  hideModeSwitch: false,
  toolbarItems: [
    ["heading", "bold", "italic", "strike"],
    ["hr", "quote"],
    ["ul", "ol", "task", "indent", "outdent"],
    ["link"],
  ],
  placeholder: "Skriv inn melding",
};
